export default [
    {code: '1000', color: '#CABE8E'},
    {code: '1001', color: '#CEB58C'},
    {code: '1002', color: '#CFAF76'},
    {code: '1003', color: '#F2AD22'},
    {code: '1004', color: '#DFA31F'},
    {code: '1005', color: '#C89625'},
    {code: '1006', color: '#DD9622'},
    {code: '1007', color: '#E5941F'},
    {code: '1011', color: '#AF885D'},
    {code: '1012', color: '#D8B33E'},
    {code: '1013', color: '#E4DDCB'},
    {code: '1014', color: '#DCC6A1'},
    {code: '1015', color: '#E5D5BB'},
    {code: '1016', color: '#EDE334'},
    {code: '1017', color: '#F2AE5D'},
    {code: '1018', color: '#F4D046'},
    {code: '1019', color: '#A69480'},
    {code: '1020', color: '#A2946D'},
    {code: '1021', color: '#EEBB00'},
    {code: '1023', color: '#F1BB01'},
    {code: '1024', color: '#B99559'},
    {code: '1026', color: '#F0FD00'},
    {code: '1027', color: '#A78734'},
    {code: '1028', color: '#FFA200'},
    {code: '1032', color: '#DBA829'},
    {code: '1033', color: '#F3A038'},
    {code: '1034', color: '#E9A35E'},
    {code: '1035', color: '#887E6B'},
    {code: '1036', color: '#856E44'},
    {code: '1037', color: '#EB982E'},
    {code: '2000', color: '#D7772D'},
    {code: '2001', color: '#BA563C'},
    {code: '2002', color: '#BE483C'},
    {code: '2003', color: '#F28044'},
    {code: '2004', color: '#DF5E34'},
    {code: '2005', color: '#FF1502'},
    {code: '2007', color: '#FF9600'},
    {code: '2008', color: '#E9723A'},
    {code: '2009', color: '#DB5E32'},
    {code: '2010', color: '#CC653C'},
    {code: '2011', color: '#E27638'},
    {code: '2012', color: '#D46B58'},
    {code: '2013', color: '#934424'},
    {code: '3000', color: '#A8403D'},
    {code: '3001', color: '#A03C3E'},
    {code: '3002', color: '#9F3B3D'},
    {code: '3003', color: '#8A383E'},
    {code: '3004', color: '#753C42'},
    {code: '3005', color: '#663D43'},
    {code: '3007', color: '#513E40'},
    {code: '3009', color: '#734641'},
    {code: '3011', color: '#7D3D3D'},
    {code: '3012', color: '#C88B78'},
    {code: '3013', color: '#99443F'},
    {code: '3014', color: '#CB797D'},
    {code: '3015', color: '#D8A5AE'},
    {code: '3016', color: '#A94C44'},
    {code: '3017', color: '#CA5F69'},
    {code: '3018', color: '#C74F59'},
    {code: '3020', color: '#BB3939'},
    {code: '3022', color: '#CD705E'},
    {code: '3024', color: '#F60100'},
    {code: '3026', color: '#FE0000'},
    {code: '3027', color: '#AD3E4F'},
    {code: '3004', color: '#753C42'},
    {code: '3005', color: '#663D43'},
    {code: '3007', color: '#513E40'},
    {code: '3009', color: '#734641'},
    {code: '3011', color: '#7D3D3D'},
    {code: '3012', color: '#C88B78'},
    {code: '3013', color: '#99443F'},
    {code: '3014', color: '#CB797D'},
    {code: '3015', color: '#D8A5AE'},
    {code: '3016', color: '#A94C44'},
    {code: '3017', color: '#CA5F69'},
    {code: '3018', color: '#C74F59'},
    {code: '3020', color: '#BB3939'},
    {code: '3022', color: '#CD705E'},
    {code: '3024', color: '#F60100'},
    {code: '3026', color: '#FE0000'},
    {code: '3027', color: '#AD3E4F'},
    {code: '3028', color: '#CB3234'},
    {code: '3031', color: '#AB464E'},
    {code: '3032', color: '#751521'},
    {code: '3033', color: '#B54740'},
    {code: '4001', color: '#8C6A8B'},
    {code: '4002', color: '#924E5D'},
    {code: '4003', color: '#C66A93'},
    {code: '4004', color: '#703B4D'},
    {code: '4005', color: '#8470A1'},
    {code: '4006', color: '#97467E'},
    {code: '4007', color: '#573E51'},
    {code: '4008', color: '#8D5789'},
    {code: '4009', color: '#A38B98'},
    {code: '4010', color: '#C05082'},
    {code: '4011', color: '#836593'},
    {code: '4012', color: '#716880'},
    {code: '5000', color: '#485B79'},
    {code: '5001', color: '#38586F'},
    {code: '5002', color: '#3F4B87'},
    {code: '5003', color: '#3B4963'},
    {code: '5004', color: '#3C3F48'},
    {code: '5005', color: '#2B5D8E'},
    {code: '5007', color: '#4F7194'},
    {code: '5008', color: '#454C56'},
    {code: '5009', color: '#3E667F'},
    {code: '5010', color: '#2B5A84'},
    {code: '5011', color: '#3C4250'},
    {code: '5012', color: '#418EBA'},
    {code: '5013', color: '#3C4661'},
    {code: '5014', color: '#71839B'},
    {code: '5015', color: '#3082B4'},
    {code: '5017', color: '#186694'},
    {code: '5018', color: '#389293'},
    {code: '5019', color: '#31678B'},
    {code: '5020', color: '#2A525A'},
    {code: '5021', color: '#097D7D'},
    {code: '5022', color: '#444267'},
    {code: '5023', color: '#5A7495'},
    {code: '5024', color: '#7098B1'},
    {code: '5025', color: '#336275'},
    {code: '5026', color: '#212A51'},
    {code: '6000', color: '#4E7D6B'},
    {code: '6001', color: '#437248'},
    {code: '6002', color: '#456540'},
    {code: '6003', color: '#5A5E4D'},
    {code: '6004', color: '#335554'},
    {code: '6005', color: '#365148'},
    {code: '6006', color: '#4E4E46'},
    {code: '6007', color: '#43493F'},
    {code: '6008', color: '#47473F'},
    {code: '6009', color: '#3F4941'},
    {code: '6010', color: '#547546'},
    {code: '6011', color: '#728567'},
    {code: '6012', color: '#444D4C'},
    {code: '6013', color: '#827C64'},
    {code: '6014', color: '#534E48'},
    {code: '6015', color: '#4A4D46'},
    {code: '6016', color: '#167259'},
    {code: '6017', color: '#5E874D'},
    {code: '6018', color: '#619F49'},
    {code: '6019', color: '#B9D1AF'},
    {code: '6020', color: '#4A5449'},
    {code: '6021', color: '#8DA080'},
    {code: '6022', color: '#4B4640'},
    {code: '6024', color: '#298C5E'},
    {code: '6025', color: '#64774A'},
    {code: '6026', color: '#07695A'},
    {code: '6027', color: '#85BEB8'},
    {code: '6028', color: '#446052'},
    {code: '6029', color: '#00784B'},
    {code: '6032', color: '#36875E'},
    {code: '6033', color: '#578F86'},
    {code: '6034', color: '#85B1B0'},
    {code: '6035', color: '#044D26'},
    {code: '6036', color: '#015D4F'},
    {code: '6037', color: '#008E38'},
    {code: '6038', color: '#00BB2E'},
    {code: '7000', color: '#838E94'},
    {code: '7001', color: '#949BA3'},
    {code: '7002', color: '#898270'},
    {code: '7003', color: '#7D7D71'},
    {code: '7004', color: '#A0A0A0'},
    {code: '7005', color: '#767875'},
    {code: '7006', color: '#7A7267'},
    {code: '7008', color: '#7A6A50'},
    {code: '7009', color: '#646760'},
    {code: '7010', color: '#616660'},
    {code: '7011', color: '#5E6367'},
    {code: '7012', color: '#656A6D'},
    {code: '7013', color: '#605C51'},
    {code: '7015', color: '#5D6065'},
    {code: '7016', color: '#4C4F54'},
    {code: '7021', color: '#47484A'},
    {code: '7022', color: '#5A5955'},
    {code: '7023', color: '#85867E'},
    {code: '7024', color: '#56575C'},
    {code: '7026', color: '#4B5355'},
    {code: '7030', color: '#97948D'},
    {code: '7031', color: '#6B727A'},
    {code: '7032', color: '#B7B4A5'},
    {code: '7033', color: '#868A7C'},
    {code: '7034', color: '#96907A'},
    {code: '7035', color: '#C9CBC8'},
    {code: '7036', color: '#9D9998'},
    {code: '7037', color: '#858585'},
    {code: '7038', color: '#B2B5AE'},
    {code: '7039', color: '#74716A'},
    {code: '7040', color: '#9EA3A7'},
    {code: '7042', color: '#949899'},
    {code: '7043', color: '#5D5F5E'},
    {code: '7044', color: '#BBB6B0'},
    {code: '7045', color: '#94989B'},
    {code: '7046', color: '#878C90'},
    {code: '7047', color: '#CACACA'},
    {code: '7048', color: '#858071'},
    {code: '8000', color: '#8B734F'},
    {code: '8001', color: '#9E6D44'},
    {code: '8002', color: '#7F5B4F'},
    {code: '8003', color: '#845A41'},
    {code: '8004', color: '#915847'},
    {code: '8007', color: '#775441'},
    {code: '8008', color: '#795B43'},
    {code: '8011', color: '#654A3F'},
    {code: '8012', color: '#6E4742'},
    {code: '8014', color: '#56483F'},
    {code: '8015', color: '#694642'},
    {code: '8016', color: '#5B443E'},
    {code: '8017', color: '#534542'},
    {code: '8019', color: '#4E4848'},
    {code: '8022', color: '#3D3C3A'},
    {code: '8023', color: '#A66442'},
    {code: '8024', color: '#7E5E4F'},
    {code: '8025', color: '#7C6255'},
    {code: '8028', color: '#5E4D43'},
    {code: '8029', color: '#80432A'},
    {code: '9001', color: '#EAE2D7'},
    {code: '9002', color: '#D8D8D0'},
    {code: '9003', color: '#EEEEEC'},
    {code: '9004', color: '#444446'},
    {code: '9005', color: '#3C3C3C'},
    {code: '9006', color: '#A6A5A2'},
    {code: '9007', color: '#8F8E8A'},
    {code: '9010', color: '#F2EFE8'},
    {code: '9011', color: '#3E3F41'},
    {code: '9016', color: '#F1F2ED'},
    {code: '9017', color: '#404040'},
    {code: '9018', color: '#CACFC9'},
    {code: '9022', color: '#A0A1A0'},
    {code: '9023', color: '#909293'},
]
export default {
    dealer: [
        {"name":"Оконная ручка (Белая)","unit":"шт","price":"100"},
    ],
    employee: [
        {"name":"Фетр (белый)","unit":"п.м.","price":"110"},
        {"name":"Фетр (коричневый)","unit":"п.м.","price":"110"},
        {"name":"Фетр (RAL7016)","unit":"п.м.","price":"110"},
        {"name":"Ручка оконная HOPP (белая)","unit":"шт","price":"800"},
        {"name":"Ручка оконная HOPP (коричневая)","unit":"шт","price":"800"},
        {"name":"Ручка оконная ROTO (белая)","unit":"шт","price":"1500"},
        {"name":"Ручка оконная ROTO (коричневая)","unit":"шт","price":"2000"},
        {"name":"Ручка оконная REHAU с детским замком (белая)","unit":"шт","price":"3000"},
        {"name":"Ручка оконная ROTO с ключем (белая)","unit":"шт","price":"5500"},
        {"name":"Ручка оконная ROTO с ключем (коричневая)","unit":"шт","price":"5500"},
        {"name":"Ручка оконная HOPP с ключем (белая) ","unit":"шт","price":"2000"},
        {"name":"Ручка оконная HOPP с ключем (коричневая) ","unit":"шт","price":"2000"},
        {"name":"Ограничитель открывания окна пластик (белая)","unit":"шт","price":"500"},
        {"name":"Ограничитель открывания окна пластик (коричневый)","unit":"шт","price":"500"},
        {"name":"Ограничитель открывания окна пластик (серый)","unit":"шт","price":"500"},
        {"name":"Ограничитель открывания окна металл (белый)","unit":"шт","price":"800"},
        {"name":"Ограничитель открывания окна металл (коричневая)","unit":"шт","price":"800"},
        {"name":"Ограничитель открывания окна металл под АЛ (белая)","unit":"шт","price":"900"},
        {"name":"Ограничитель открывания окна металл под АЛ (коричневая)","unit":"шт","price":"900"},
        {"name":"Шпингалет ","unit":"шт","price":"600"},
        {"name":"Детски замок на тросике  (белый)","unit":"шт","price":"2000"},
        {"name":"Детски замок на тросике  (коричневый)","unit":"шт","price":"2000"},
        {"name":"Детский замок под створку (белый)","unit":"шт","price":"2000"},
        {"name":"Детский замок под створку (коричневый)","unit":"шт","price":"2000"},
        {"name":"Ручка REHAU с детским замком","unit":"шт","price":"2300"},
        {"name":"Ручка ракушка пластиковая (белая)","unit":"шт","price":"500"},
        {"name":"Ручка ракушка пластиковая (коричневая)","unit":"шт","price":"500"},
        {"name":"Ручка ракушка пластиковая (серая)","unit":"шт","price":"500"},
        {"name":"Ручка ракушка пластиковая (чёрная)","unit":"шт","price":"500"},
        {"name":"Ручка ракушка металлическая (беллая)","unit":"шт","price":"800"},
        {"name":"Ручка ракушка металлическая (коричневая)","unit":"шт","price":"800"},
        {"name":"Ручка ракушка металлическая (серая)","unit":"шт","price":"800"},
        {"name":"Ручка ракушка металлическая (чёрная)","unit":"шт","price":"800"},
        {"name":"Ручка двухстороння симметричная (белая). ","unit":"шт","price":"4000"},
        {"name":"Ручка двухстороння симметричная (коричневая). ","unit":"шт","price":"4000"},
        {"name":"Ручка двухстороння ассиметричная (белая). ","unit":"шт","price":"4000"},
        {"name":"Ручка двухстороння ассиметричная (коричневая). ","unit":"шт","price":"4000"},
        {"name":"Ручка двухстороння с ключом (белая). ","unit":"шт","price":"6000"},
        {"name":"Ручка двухстороння с ключом (коричневая). ","unit":"шт","price":"6000"},
        {"name":"Лючок Мини 240*290","unit":"шт","price":"3500"},
        {"name":"Лючок Макс 350*450","unit":"шт","price":"4000"},
        {"name":"Уплотнитель щеточный (Шлегель) 12мм серый","unit":"пог.м.","price":"110"},
        {"name":"Уплотнитель щеточный (Шлегель) 12мм коичневый","unit":"пог.м.","price":"110"},
        {"name":"Уплотнитель щеточный (Шлегель) 12мм белый","unit":"пог.м.","price":"110"},
        {"name":"Уплотнитель щеточный (Шлегель) 12мм чёрный","unit":"пог.м.","price":"110"},
        {"name":"Двухсторонней скотч черный","unit":"пог.м.","price":"150"},
        {"name":"Двухсторонней скотч белый","unit":"пог.м.","price":"150"},
        {"name":"Крепление «VSN»","unit":"шт","price":"250"},
        {"name":"Металлическая Ручка МС (Белые)","unit":"шт","price":"120"},
        {"name":"Металлическая Ручка МС (Коричневые)","unit":"шт","price":"120"},
        {"name":"Металлическая Ручка МС (Серые)","unit":"шт","price":"120"},
        {"name":"Металлическая Ручка МС (Чёрные)","unit":"шт","price":"120"},
        {"name":"Пластиковая Ручка МС (Белые)","unit":"шт","price":"120"},
        {"name":"Пластиковая Ручка МС (Коричневые)","unit":"шт","price":"120"},
        {"name":"Пластиковая Ручка МС (Серые)","unit":"шт","price":"120"},
        {"name":"Пластиковая Ручка МС (Чёрные)","unit":"шт","price":"120"},
        {"name":"«Флажки» крепления для сеток (Белые)","unit":"шт","price":"120"},
        {"name":"«Флажки» крепления для сеток (Коричневые)","unit":"шт","price":"120"},
        {"name":"Z-крепления металлические Белые (комплект) 4 шт.","unit":"компл.","price":"450"},
        {"name":"Z-крепления металлические Коричневые (комплект) 4 шт.","unit":"компл.","price":"450"},
        {"name":"Z-крепления металлические Серые (комплект) 4 шт.","unit":"компл.","price":"450"},
        {"name":"Z-крепления металлические Чёрные (комплект) 4 шт.","unit":"компл.","price":"450"},
        {"name":"Z-крепления пластиковые Белые (комплект) 4 шт.","unit":"компл.","price":"220"},
        {"name":"Z-крепления пластиковые Коричневые (комплект) 4 шт.","unit":"компл.","price":"220"},
        {"name":"Z-крепления пластиковые Серые (комплект) 4 шт.","unit":"компл.","price":"220"},
        {"name":"Z-крепления пластиковые Чёрные (комплект) 4 шт.","unit":"компл.","price":"220"},
        {"name":"Плунжерные крепления 1шт. ","unit":"шт","price":"225"},
        {"name":"Петли с доводчиками (Белые) ","unit":"шт","price":"600"},
        {"name":"Петли с доводчиками (Коричневые)","unit":"шт","price":"600"},
        {"name":"Петли без доводчиков (Белые) ","unit":"шт","price":"400"},
        {"name":"Петли без доводчиков (Коричневые)","unit":"шт","price":"400"},
        {"name":"Петли без доводчиков (Серые) ","unit":"шт","price":"400"},
        {"name":"Петли без доводчиков (Чёрные)","unit":"шт","price":"400"},
        {"name":"Петли усиленные (Белые) ","unit":"шт","price":"600"},
        {"name":"Петли усиленные (Коричневые)","unit":"шт","price":"600"},
        {"name":"Магнитный держатель (Белый) ","unit":"шт","price":"100"},
        {"name":"Магнитный держатель (Коричневый) ","unit":"шт","price":"100"},
        {"name":"Магнитный держатель (Серый) ","unit":"шт","price":"100"},
        {"name":"Магнитный держатель (Чёрный) ","unit":"шт","price":"100"},
        {"name":"Защелка (Белая) ","unit":"шт","price":"100"},
        {"name":"Защелка (Коричневая) ","unit":"шт","price":"100"},
        {"name":"Защелка (Серая) ","unit":"шт","price":"100"},
        {"name":"Защелка (Чёрная) ","unit":"шт","price":"100"},
        {"name":"Профиль 25мм (Белый)","unit":"пог.м.","price":"400"},
        {"name":"Профиль 25мм (Коричневый) ","unit":"пог.м.","price":"400"},
        {"name":"Профиль 25мм (Серый)","unit":"пог.м.","price":"400"},
        {"name":"Профиль 25мм (Чёрный)","unit":"пог.м.","price":"400"},
        {"name":"Профиль 42мм (Белый)","unit":"пог.м.","price":"550"},
        {"name":"Профиль 42мм (Коричневый) ","unit":"пог.м.","price":"550"},
        {"name":"Профиль 42мм (Серый) ","unit":"пог.м.","price":"550"},
        {"name":"Профиль 42мм (Чёрный) ","unit":"пог.м.","price":"550"},
        {"name":"Профиль Поперечный (Белый)","unit":"пог.м.","price":"400"},
        {"name":"Профиль Поперечный (Коричневый) ","unit":"пог.м.","price":"400"},
        {"name":"Профиль Поперечный (Серый)","unit":"пог.м.","price":"400"},
        {"name":"Профиль Поперечный (Чёрный)","unit":"пог.м.","price":"400"},
        {"name":"Профиль Плиссе 22мм (Белый). ","unit":"пог.м.","price":"770"},
        {"name":"Профиль Плиссе 22мм (Коричневый). ","unit":"пог.м.","price":"770"},
        {"name":"Полотно антимоситное серое, 1,6м.","unit":"пог.м.","price":"1584"},
        {"name":"Полотно антимоситное чёрное, 1,6м. ","unit":"пог.м.","price":"1584"},
        {"name":"Полотно антикот белый, 1,6м. ","unit":"пог.м.","price":"3279"},
        {"name":"Полотно антикот серый, 1,6м. ","unit":"пог.м.","price":"3279"},
        {"name":"Полотно антикот черный, 1,6м. ","unit":"пог.м.","price":"3279"},
        {"name":"Полотно антикот бронзовый, 1,6м. ","unit":"пог.м.","price":"3279"},
        {"name":"Полотно антикот черно-белый, 1,6м. ","unit":"пог.м.","price":"3279"},
        {"name":"Полотно антикот белый блеск, 1,6м. ","unit":"пог.м.","price":"3279"},
        {"name":"Полотно антикот коричневый, 1,6м. ","unit":"пог.м.","price":"3279"},
        {"name":"Полотно ультравью черное, 1,6м. ","unit":"пог.м.","price":"3279"},
        {"name":"Полотно антимошка черная, 1,6м.","unit":"пог.м.","price":"3036"},
        {"name":"Полотно антипыль серая, 1,6м.","unit":"пог.м.","price":"3036"},
        {"name":"Полотно антипыльца черная, 1,6м. ","unit":"пог.м.","price":"3886"},
        {"name":"Полотно антибактериальная, 1,6м. ","unit":"пог.м.","price":"5222"},
        {"name":"Полотно светоотражающее, 1,6м. ","unit":"пог.м.","price":"3943"},
        {"name":"Полотно диаманд, 1,6м. ","unit":"пог.м.","price":"3590"},
        {"name":"Полотно антипыль Италия серая, 1,6м. ","unit":"пог.м.","price":"3836"},
        {"name":"Полотно антипыль Италия черная, 1,6м. ","unit":"пог.м.","price":"3836"},  
    ]
}
export default {
 
    name: 'Калькулятор москитных сеток',
    description: "Калькулятор москитных сеток",  
    
    user: {
        id: "1",
        name: "Nikolay",
        email: "nikolay@example.com",
        role: "employee"
        // role: "dealer"
    },
    
    webhooks: {
        //bitrix24: 'https://geo-setki.ru/bitrix/index.php?action=bitrix',
		//bitrix24: 'https://geo-setki.ru/controller.php'
    }

}
import Button from "../../components/buttons/Button.js";
import Order from "./Order.js";
import OrderForm from './OrderForm.js';
import OrderTableProducts from './OrderTableProducts.js'; 
import { fetchOrderToBitrix24 } from '../../api.js'; 
import DTOOrderBitrix from '../../dto/DTOOrderBitrix.js';


const fs = require('fs');
const path = require('path');

class OrderTotal {
    constructor() {
        this.$wrapper = document.createElement('div');
        this.$buttonOrder = new Button('button_order').setText('Отправить в CRM').setIcon('icon-loader');
        this.$buttonSave = new Button('button_save').setText('Сохранить в PDF');
        this.$actions = document.createElement('div');
        this.$total = document.createElement('div');

        this.$wrapper.className = 'container';
        this.$total.className = 'order-total';
        this.$actions.className = 'order-actions';
        this.$buttonOrder.setClass('button button-order');
        this.$buttonSave.setClass('button button-save');
        //this.$actions.append(this.$buttonOrder.render());
        this.$wrapper.append(this.$total, this.$actions);

        this.setButtonOrderActions();
    }

    setButtonOrderActions() {
        this.$buttonOrder.on('click', () => {
            if (OrderForm.validate()) {
                this.sendOrderToBitrix();
            }
        });
    }

    sendOrderToBitrix() {
        // Existing code
    }

    update(order) {
        // Existing update logic
        OrderTableProducts.update(order);
    }

    render() {
        this.$actions.append(this.$buttonSave.render());
        this.$buttonSave.on('click', (event) => {
            event.preventDefault();  // Prevent default action to avoid page refresh
            
            // Save the current order as PDF
            OrderTableProducts.saveAsPDF();

            // Increment calculation count in localStorage
            let calcNumber = parseInt(localStorage.getItem('calcNumber')) || 0;
            calcNumber += 1;
            localStorage.setItem('calcNumber', calcNumber);

            // Send log data to backend
            this.logCalculation(calcNumber);
        });

        return this.$wrapper;
    }
    
    logCalculation(calcNumber) {
        this.getIpAddress().then(ipAddress => {
            console.log('Отправка данных на сервер:', { calcNumber, ipAddress });  // Отладочное сообщение
            fetch('https://calc.moskitok.ru/save-log', {  // Убираем порт
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ calcNumber, ipAddress }),
            })
            .then(response => {
                console.log('Статус ответа:', response.status);  // Логируем статус ответа
                if (!response.ok) {
                    throw new Error('Ошибка при сохранении лога на сервере');
                }
                return response.text();
            })
            .then(data => {
                console.log('Ответ от сервера:', data);  // Успешное сообщение от сервера
            })
            .catch(error => {
                console.error('Ошибка при отправке данных на сервер:', error);
            });
        }).catch(error => {
            console.error('Ошибка при получении IP адреса:', error);
        });
    }
    
    getIpAddress() {
        return fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => data.ip)
            .catch(error => {
                console.error('Ошибка при запросе IP адреса:', error);
                throw error;
            });
    }
    

}

export default new OrderTotal();
export default {
    // Диллер
    dealer: {    
        ral: { price: 300, min: 6000 }
    },
    // Монтажник
    employee: {
        ral: { price: 400, min: 8000 },
    },
    
}
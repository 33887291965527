import Button from "./Button.js";

export default class ButtonDelete extends Button {

    constructor(key='button-delete') {
        super(key);

        this.setIconSVG(`
            <svg width="18" height="18" viewBox="0 0 18 18" fill="#ff0000" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 0C4.037 0 0 4.038 0 9C0 13.962 4.037 18 9 18C13.963 18 18 13.962 18 9C18 4.038 13.963 0 9 0ZM9 16C5.141 16 2 12.86 2 9C2 5.14 5.141 2 9 2C12.859 2 16 5.14 16 9C16 12.86 12.859 16 9 16ZM9.707 9L12.353 6.354C12.4462 6.25998 12.4986 6.13292 12.4986 6.0005C12.4986 5.86808 12.4462 5.74102 12.353 5.647C12.259 5.55375 12.1319 5.50143 11.9995 5.50143C11.8671 5.50143 11.74 5.55375 11.646 5.647L9 8.293L6.354 5.646C6.2597 5.55492 6.1334 5.50452 6.0023 5.50566C5.8712 5.5068 5.74579 5.55939 5.65309 5.65209C5.56039 5.7448 5.5078 5.8702 5.50666 6.0013C5.50552 6.1324 5.55592 6.2587 5.647 6.353L8.293 9L5.647 11.646C5.55311 11.7398 5.50032 11.867 5.50022 11.9996C5.50013 12.1323 5.55275 12.2596 5.6465 12.3535C5.74025 12.4474 5.86746 12.5002 6.00015 12.5003C6.13283 12.5004 6.26011 12.4478 6.354 12.354L9 9.707L11.646 12.353C11.6921 12.4008 11.7472 12.439 11.8081 12.4653C11.8691 12.4916 11.9347 12.5054 12.0011 12.5061C12.0675 12.5068 12.1333 12.4942 12.1948 12.4692C12.2563 12.4441 12.3122 12.4071 12.3592 12.3602C12.4062 12.3133 12.4434 12.2575 12.4686 12.1961C12.4938 12.1347 12.5066 12.0689 12.5061 12.0025C12.5056 11.9361 12.4919 11.8705 12.4658 11.8095C12.4397 11.7484 12.4017 11.6932 12.354 11.647L9.707 9Z" />
            </svg>
        `)

        this.setTitle('Удалить')
    }

}
